<template>
  <a-card :bordered="false" class="card-area">
    
    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="角色">
              <a-input v-model="queryParams.roleName"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="创建时间">
              <range-date @change="handleDateChange" ref="createTime"></range-date>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
          </a-col>
          <a-col :span="12" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="search">查询</a-button>
            <a-button style="margin-left: 8px" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <div class="operator">
        <a-button v-hasPermission="['role:add']" ghost type="primary" @click="add">新增</a-button>
        <a-button v-hasPermission="['role:delete']" @click="batchDelete">删除</a-button>
        <a-button v-hasPermission="['role:export']" @click="exprotExccel">导出Excel</a-button>
      </div>
      <!-- 表格区域 -->
      <a-table bordered ref="TableInfo"
               :columns="columns"
               :rowKey="record => record.roleId"
               :dataSource="dataSource"
               :pagination="pagination"
               :loading="loading"
               :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
               :scroll="{ x: 900 }"
               @change="handleTableChange">
        <template slot="remark" slot-scope="text">
          <a-popover placement="topLeft">
            <template slot="content">
              <div style="max-width: 200px">{{text}}</div>
            </template>
            <p style="width: 200px;margin-bottom: 0">{{text}}</p>
          </a-popover>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-icon v-hasPermission="['role:update']" type="edit" theme="twoTone" twoToneColor="#4a9ff5" @click="edit(record)" title="修改角色"></a-icon>
          &nbsp;
          <a-icon type="eye" theme="twoTone" twoToneColor="#42b983" @click="view(record)" title="查看"></a-icon>
        </template>
      </a-table>
      <!-- 角色信息查看 -->
      <role-info
        @close="handleRoleInfoClose"
        :roleInfoVisiable="roleInfo.visiable"
        :dataScope="this.dataScope"
        :roleInfoData="roleInfo.data">
      </role-info>
      <!-- 新增角色 -->
      <role-add
        @close="handleRoleAddClose"
        @success="handleRoleAddSuccess"
        :dataScope="this.dataScope"
        :roleAddVisiable="roleAdd.visiable">
      </role-add>
      <!-- 修改角色 -->
      <role-edit
        ref="roleEdit"
        :roleInfoData="roleInfo.data"
        @close="handleRoleEditClose"
        @success="handleRoleEditSuccess"
        :dataScope="this.dataScope"
        :roleEditVisiable="roleEdit.visiable">
      </role-edit>
    </div>
  </a-card>
</template>

<script>
import RangeDate from '@/components/datetime/RangeDate'
import RoleAdd from './RoleAdd'
import RoleInfo from './RoleInfo'
import RoleEdit from './RoleEdit'
import {mapState} from 'vuex'
export default {
  name: 'Role',
  components: {RangeDate, RoleInfo, RoleAdd, RoleEdit},
  data () {
    return {
      advanced: false,
      roleInfo: {
        visiable: false,
        data: {}
      },
      roleAdd: {
        visiable: false
      },
      roleEdit: {
        visiable: false
      },
      queryParams: {
        createTimeFrom: '',
        createTimeTo: ''
      },
      dataSource: [],
      filteredInfo: null,
      sortedInfo: null,
      paginationInfo: null,
      selectedRowKeys: [],
      pagination: {
        pageSizeOptions: ['10', '20', '30', '40', '100'],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      loading: false
    }
  },
  computed: {
    ...mapState({
      dataScope: state => state.dict.dicts.t_role_data_scope
    }),
    columns () {
      let { sortedInfo, filteredInfo } = this
      sortedInfo = sortedInfo || {}
      filteredInfo = filteredInfo || {}
      let dataFilters = []
      for (let index in this.dataScope) {
        let obj = {text: this.dataScope[index].valuee, value: this.dataScope[index].keyy}
        dataFilters.push(obj)
      }
      return [{
        title: '角色',
        dataIndex: 'roleName',
        width: 150
      }, {
        title: '描述',
        dataIndex: 'remark',
        scopedSlots: { customRender: 'remark' }
      }, 
      
      // {
      //   title: '数据范围',
      //   dataIndex: 'dataScope',
      //   customRender: (text, row, index) => {
      //     for (let _index in this.dataScope) {
      //       let key = Number(this.dataScope[_index].keyy)
      //       if (text === key) {
      //         return this.dataScope[_index].valuee
      //       } else {
      //         continue
      //       }
      //     }
      //     return text
      //   },
      //   filters: dataFilters,
      //   filterMultiple: false,
      //   filteredValue: filteredInfo.dataScope || null,
      //   onFilter: (value, record) => parseInt(value) === record.dataScope,
      //   width: 200
      // }, 
      
      {
        title: '创建时间',
        dataIndex: 'createTime',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'createTime' && sortedInfo.order,
        customRender: (text) => { return this.datetime(text) },
        width: 200
      }, {
        title: '修改时间',
        dataIndex: 'modifyTime',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'modifyTime' && sortedInfo.order,
        customRender: (text) => { return this.datetime(text) },
        width: 200
      }, {
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        scopedSlots: { customRender: 'operation' }
      }]
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    add () {
      this.roleAdd.visiable = true
    },
    handleRoleAddClose () {
      this.roleAdd.visiable = false
    },
    handleRoleAddSuccess () {
      this.roleAdd.visiable = false
      this.$message.success('新增角色成功')
      this.search()
    },
    view (record) {
      this.roleInfo.data = record
      this.roleInfo.visiable = true
    },
    handleRoleInfoClose () {
      this.roleInfo.visiable = false
    },
    edit (record) {
      this.$refs.roleEdit.setFormValues(record)
      this.roleInfo.data = record
      this.roleEdit.visiable = true
    },
    handleRoleEditSuccess () {
      this.roleEdit.visiable = false
      this.$message.success('修改角色成功')
      this.search()
    },
    handleRoleEditClose () {
      this.roleEdit.visiable = false
    },
    handleDateChange (value) {
      if (value) {
        this.queryParams.createTimeFrom = value[0]
        this.queryParams.createTimeTo = value[1]
      }
    },
    batchDelete () {
      if (!this.selectedRowKeys.length) {
        this.$message.warning('请选择需要删除的记录')
        return
      }
      let that = this
      this.$confirm({
        title: '确定删除所选中的记录?',
        content: '当您点击确定按钮后，这些记录将会被彻底删除',
        centered: true,
        onOk () {
          let roleIds = that.selectedRowKeys.join(',')
          // let selectedRowKeysStr = ',' + that.selectedRowKeys.join(',') + ','
          // roleIds.push(that.dataSource.filter(f => { return selectedRowKeysStr.indexOf(',' + f.roleId + ',') > -1 }).map(m => { return m.roleId }))
          that.$delete('role/' + roleIds).then(() => {
            that.$message.success('删除成功')
            that.selectedRowKeys = []
            that.search()
          })
        },
        onCancel () {
          that.selectedRowKeys = []
        }
      })
    },
    exprotExccel () {
      let {sortedInfo, filteredInfo} = this
      let sortField, sortOrder
      // 获取当前列的排序和列的过滤规则
      if (sortedInfo) {
        sortField = sortedInfo.field
        sortOrder = sortedInfo.order
      }
      this.$export('role/excel', {
        sortField: sortField,
        sortOrder: sortOrder,
        ...this.queryParams,
        ...filteredInfo
      })
    },
    search () {
      let {sortedInfo, filteredInfo} = this
      let sortField, sortOrder
      // 获取当前列的排序和列的过滤规则
      if (sortedInfo) {
        sortField = sortedInfo.field
        sortOrder = sortedInfo.order
      }
      this.selectData({
        sortField: sortField,
        sortOrder: sortOrder,
        ...this.queryParams,
        ...filteredInfo
      })
    },
    reset () {
      // 取消选中
      this.selectedRowKeys = []
      // 重置分页
      this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent
      if (this.paginationInfo) {
        this.paginationInfo.current = this.pagination.defaultCurrent
        this.paginationInfo.pageSize = this.pagination.defaultPageSize
      }
      // 重置列过滤器规则
      this.filteredInfo = null
      // 重置列排序规则
      this.sortedInfo = null
      // 重置查询参数
      this.queryParams = {}
      // 清空时间选择
      this.$refs.createTime.reset()
      this.fetch()
    },
    handleTableChange (pagination, filters, sorter) {
      // 将这两个参数赋值给Vue data，用于后续使用
      this.paginationInfo = pagination
      this.filteredInfo = filters
      this.sortedInfo = sorter
      this.fetch({
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...this.queryParams,
        ...filters
      })
    },
    fetch (params = {}) {
      this.loading = true
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize
        params.pageSize = this.paginationInfo.pageSize
        params.pageNum = this.paginationInfo.current
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize
        params.pageNum = this.pagination.defaultCurrent
      }
      this.$get('role', {
        ...params
      }).then((r) => {
        let data = r.data
        const pagination = { ...this.pagination }
        pagination.total = data.total
        this.dataSource = data.rows
        this.pagination = pagination
        this.loading = false
      })
    },
    selectData (params = {}) {
      this.loading = true
      // 如果分页信息为空，则设置为默认值
      this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent
      this.$refs.TableInfo.pagination.pageSize = this.pagination.defaultPageSize
      params.pageSize = this.pagination.defaultPageSize
      params.pageNum = this.pagination.defaultCurrent
      this.$get('role', {
        ...params
      }).then((r) => {
        let data = r.data
        const pagination = { ...this.pagination }
        pagination.total = data.total
        this.loading = false
        this.dataSource = data.rows
        this.pagination = pagination
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>
